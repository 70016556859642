import React, { useState } from "react";
import head_img from "../images/head-profile.jpg";
import Hometable from "./AllContacts";

const Header = (props) => {
  return (
    <>
      <header>
        <div className="main-head">
          <div className="head-text">
            <div className="serch-head">
              <input
                type="text"
                name="search"
                placeholder="Search"
                onChange={(event) => {
                  props.value(event.target.value);
                }}
              />
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0644 10.506C9.11817 11.2076 7.94665 11.6225 6.67821 11.6225C3.53515 11.6225 0.987165 9.07461 0.987165 5.93152C0.987165 2.78843 3.53512 0.240479 6.67821 0.240479C9.8213 0.240479 12.3693 2.7884 12.3693 5.9315C12.3693 7.20861 11.9486 8.38747 11.2383 9.33707L13.7595 11.8583L13.7642 11.863C14.0804 12.1819 14.0783 12.6966 13.7595 13.0128C13.6061 13.165 13.3984 13.2498 13.1823 13.2486C12.9662 13.2498 12.7585 13.165 12.605 13.0128L10.0644 10.506ZM9.47615 8.88044C8.74734 9.57215 7.76234 9.99655 6.67821 9.99655C4.43315 9.99655 2.61319 8.17658 2.61319 5.93152C2.61319 3.68646 4.43318 1.8665 6.67821 1.8665C8.92327 1.8665 10.7432 3.68646 10.7432 5.93152C10.7432 7.02415 10.3122 8.0161 9.61081 8.74656C9.58188 8.76943 9.55414 8.79449 9.52781 8.82174C9.50951 8.84069 9.49229 8.86028 9.47615 8.88044Z"
                    fill="#244961"
                    fillOpacity="0.9"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="13.0128"
                      height="13.0128"
                      fill="white"
                      transform="matrix(-1 0 0 1 14 0.238037)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="head-filter">
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.625 12.8438H7.3125C7.3125 13.4987 6.77991 14.0312 6.125 14.0312H4.9375C4.28259 14.0312 3.75 13.4987 3.75 12.8438H1.375C1.04725 12.8438 0.78125 12.5783 0.78125 12.25C0.78125 11.9217 1.04725 11.6562 1.375 11.6562H3.75C3.75 11.0013 4.28259 10.4688 4.9375 10.4688H6.125C6.77991 10.4688 7.3125 11.0013 7.3125 11.6562H15.625C15.9528 11.6562 16.2188 11.9217 16.2188 12.25C16.2188 12.5783 15.9528 12.8438 15.625 12.8438ZM4.9375 11.6562V12.8438H6.125L6.12559 12.2524C6.12559 12.2512 6.125 12.2506 6.125 12.25C6.125 12.2494 6.12559 12.2488 6.12559 12.2476V11.6562H4.9375Z"
                  fill="#6D8291"
                />
                <path
                  d="M15.625 8.09375H12.0625C12.0625 8.74866 11.5299 9.28125 10.875 9.28125H9.6875C9.03259 9.28125 8.5 8.74866 8.5 8.09375H1.375C1.04725 8.09375 0.78125 7.82834 0.78125 7.5C0.78125 7.17166 1.04725 6.90625 1.375 6.90625H8.5C8.5 6.25134 9.03259 5.71875 9.6875 5.71875H10.875C11.5299 5.71875 12.0625 6.25134 12.0625 6.90625H15.625C15.9528 6.90625 16.2188 7.17166 16.2188 7.5C16.2188 7.82834 15.9528 8.09375 15.625 8.09375ZM9.6875 6.90625V8.09375H10.875L10.8756 7.50237C10.8756 7.50119 10.875 7.50059 10.875 7.5C10.875 7.49941 10.8756 7.49881 10.8756 7.49763V6.90625H9.6875Z"
                  fill="#6D8291"
                />
                <path
                  d="M15.625 3.34375H7.3125C7.3125 3.99866 6.77991 4.53125 6.125 4.53125H4.9375C4.28259 4.53125 3.75 3.99866 3.75 3.34375H1.375C1.04725 3.34375 0.78125 3.07834 0.78125 2.75C0.78125 2.42166 1.04725 2.15625 1.375 2.15625H3.75C3.75 1.50134 4.28259 0.96875 4.9375 0.96875H6.125C6.77991 0.96875 7.3125 1.50134 7.3125 2.15625H15.625C15.9528 2.15625 16.2188 2.42166 16.2188 2.75C16.2188 3.07834 15.9528 3.34375 15.625 3.34375ZM4.9375 2.15625V3.34375H6.125L6.12559 2.75237C6.12559 2.75119 6.125 2.75059 6.125 2.75C6.125 2.74941 6.12559 2.74881 6.12559 2.74763V2.15625H4.9375Z"
                  fill="#6D8291"
                />
              </svg>
              <h4>Filter</h4>
              <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5.5 5.5L10 1"
                  stroke="#CCCCCC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="head-text h-text">
            <div className="right-text-head">
              <div className="pro-text">
                <h3>Trevor Virtue</h3>
                <p>View Profile</p>
              </div>
              <span className="head-profile"></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
